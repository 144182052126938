import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import './Form.css'

const Form = ({ config, submitForm }) => {
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit'
  })

  const [submitted, setSubmitted] = useState(false)

  const [submittedTimes, setSubmittedTimes] = useState(0)

  const increaseSubmits = () => {
    setSubmittedTimes(submittedTimes + 1)
  }

  const onSubmit = data => {
    submitForm({ ...data, submits: submittedTimes + 1 })
    setSubmitted(true)
  }

  const onError = err => {
    increaseSubmits()
  }

  const header = config?.submitDetails?.header
  const body = config?.submitDetails?.body?.join(' ')

  return (
    <form id="questionaire" name="assign_potal" onSubmit={handleSubmit(onSubmit, onError)} noValidate style={{ textAlign: 'left' }}>
      {submitted
        ?
        <>
          <div className="message-container">

            <h2 className="content-sub-header" style={{ marginTop: 0 }}>
              {header}
              <span className="tick" />
            </h2>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </>
        :
        <>
          <div className="alert">
            <p>Note you have to answer each question correctly to submit this form.</p>
          </div>
          {config.questionnaire.map((field, largeIndex) => (
            <div key={`question-${largeIndex}`}>
              <h2 className="content-sub-sub-header">{largeIndex + 1}. {field.title}</h2>
              {field.type === 'input' && <input ref={register({ required: field.required })} name={`question-${largeIndex}`} type="text" placeholder={field.placeholder} />}
              {field.type === 'radio' && (
                <div style={{ marginBottom: '12px' }}>
                  <div className="custom-checkradio-container">
                    <div className="radio">
                      {field.options.map((opt, smallIndex) => (
                        <div key={opt}>
                          <input ref={register({
                            required: field.required,
                            validate: (answer) => answer === field.answer || 'That\'s not the correct answer, try again'
                          })}
                            key={`question-${largeIndex}`}
                            type="radio" id={`field-${largeIndex}-${opt}`} name={`question-${largeIndex}`} value={opt} />
                          <label style={{ fontSize: '14px' }} htmlFor={`field-${largeIndex}-${opt}`}>{opt}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {(errors && errors[`question-${largeIndex}`] && errors[`question-${largeIndex}`].type !== 'validate') && <div style={{ color: 'red', marginBottom: '15px' }}>This field is required</div>}
              {(errors && errors[`question-${largeIndex}`] && errors[`question-${largeIndex}`].type === 'validate') && <div style={{ color: 'red', marginBottom: '15px' }}>{errors[`question-${largeIndex}`].message}</div>}
            </div>
          ))}
          <button className="submit-button" type="submit" value="Submit form">Submit</button>
        </>
      }
    </form>
  )
};

Form.propTypes = {
  config: PropTypes.object,
  submitForm: PropTypes.func,
};

export default Form;