import React from 'react';
import PropTypes from 'prop-types';
import MessageContainer from './MessageContainer';

const SuccesScreen = ({ config, updatePage }) => {
  const success = config?.success
  return (
    <MessageContainer>
      <h1 className="content-header" style={{ margin: 0 }}>{success.header}</h1>
      <div dangerouslySetInnerHTML={{ __html: success.body.join(' ') }} />
      <button className="submit-button" style={{ cursor: 'pointer' }} onClick={updatePage}>{success.cta}</button>
    </MessageContainer>
  )
}

SuccesScreen.propTypes = {
  config: PropTypes.object,
  closeScreen: PropTypes.func,
};

export default SuccesScreen;