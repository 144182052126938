import React from 'react';
import PropTypes from 'prop-types';

const Dot = ({ instructions, dot, index, user_is_completed, onClickOpen, closeClick, open, goToNext }) => {
  const user_finished_dots = instructions.filter(dot => dot.closed).length + 1
  const total_dots = instructions.length

  const clickOpen = dot => {
    if (open) return false
    onClickOpen(dot)
  }

  return (
    <div style={{
      position: 'absolute',
      left: dot.position.y,
      top: dot.position.x,
      zIndex: dot.active ? 2 : 1,
    }}
    >
      <div className={`ring-container ${user_is_completed && 'ring-container--disabled'}`} onClick={() => clickOpen(dot)}>
        <div className={`
        ringring 
        ${open ? 'ringring--disabled' : ''}
        ${dot.closed ? 'ringring--finished' : ''}
        ${dot.active ? 'ringring--active' : ''}
      `}></div>
        <div className={`
        circle
        ${open ? 'circle--disabled' : ''}
        ${dot.closed ? 'circle--finished' : ''}
        ${dot.active ? 'circle--active' : ''}
      `}></div>
      </div>
      {dot.active &&
        <div className={`
              tooltip-container
              ${dot.placement === 'bottom' && 'tooltip-container--bottom'}
              ${dot.placement === 'right' && 'tooltip-container--right'}
              ${dot.placement === 'left' && 'tooltip-container--left'}
              ${dot.placement === 'top' && 'tooltip-container--top'}
            `} style={{
          }}>
          <div style={{ position: 'relative' }}>
            <div className="close" onClick={() => closeClick(index)} />
            <h3 className="content-sub-header tooltip-header" style={{ margin: 0 }}>{dot.title}</h3>
            <div className='tooltip-scroll-container'>
              <div dangerouslySetInnerHTML={{ __html: dot.body.join(' ') }} />
              <div style={{ color: '#ccc', textAlign: 'right', marginTop: '20px' }}>
                {user_finished_dots}/{total_dots}

                {/* //finished steps */}
                {(user_finished_dots === total_dots)
                  ? (<button
                    onClick={() => closeClick(index)}
                    className={'cta-small'}
                    style={{ marginLeft: '10px' }}>
                    Ok
                  </button>)
                  :
                  (<button
                    onClick={() => {
                      goToNext(index)
                    }}
                    className={'cta-small'}
                    style={{ marginLeft: '10px' }}>
                    Next
                  </button>)
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

Dot.propTypes = {
  instructions: PropTypes.array,
  dot: PropTypes.object,
  index: PropTypes.number,
  user_is_completed: PropTypes.bool,
  onClickOpen: PropTypes.func,
  closeClick: PropTypes.func,
  open: PropTypes.bool,
  goToNext: PropTypes.func,
};

export default Dot;
