import React from 'react';
import PropTypes from 'prop-types';
import './Dots.css'
import Dot from './Dot'

const Dots = ({ instructions, openClick, closeClick, open, goToNext }) => {
  const user_is_completed = instructions.reduce((aggr, dot) => {
    if (dot.active || !dot.closed) return false
    return aggr
  }, true)

  const onClickOpen = (dot) => {
    if (user_is_completed) {
      return false
    } else {
      openClick(dot)
    }
  }
  return (
    <div>
      {instructions.map((dot, index, array) =>
        <Dot user_is_completed={user_is_completed} instructions={instructions} key={`dot-${index}`} dot={dot} index={index} onClickOpen={onClickOpen} closeClick={closeClick} open={open} goToNext={goToNext} />
      )}
    </div>
  )
}
Dots.propTypes = {
  instructions: PropTypes.array,
  openClick: PropTypes.func,
  closeClick: PropTypes.func,
  open: PropTypes.bool,
  goToNext: PropTypes.func,
};

export default Dots;