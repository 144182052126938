/**
 * Todo:
 * add classNames lib 
 */


import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect, useReducer, useState } from 'react';
import './App.css';
import Dots from './Components/Dots';
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Form from './Components/Form';
import SuccesScreen from './Components/SuccesScreen';
import WelcomeScreen from './Components/WelcomeScreen';
import config from './config';
import AppReducer from './Helpers/AppReducer';
import useDebounceEffect from './Helpers/useDebounceEffect';
import './Test.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';



function App() {
  const parsed = queryString.parse(window.location.search);
  const [identifier] = useState(parsed.identifier)
  const [email] = useState(parsed.email)

  const [html, setHtml] = useState(null)

  useEffect(() => {

    // loads login screen
    axios.get('login.html')
      .then(response => {
        setHtml(response.data)
      })

  }, [])

  const [data, dispatch] = useReducer(AppReducer, config)

  const submitForm = (data) => {
    dispatch({ type: 'submit-form', item: data })
  }

  const sendNetworkRquest = (data) => {
    axios.post('/api/v1/records/', {
      email: email,
      identifier: identifier,
      results: data
    })
      .catch(err => {
        NotificationManager.error('Something went wrong, try again later', 'Ops', 3000);
      })
  }

  useDebounceEffect(() => {
    sendNetworkRquest(data)
  }, 250, [data])

  useEffect(() => {
    const userFinished = data.instructions.reduce((aggr, item) => {
      if (!item.closed) aggr = false
      return aggr
    }, true)

    dispatch({ type: 'finish-training', item: userFinished })

  }, [data.instructions])

  const openClick = item => {
    dispatch({ type: 'open-dot', item })
  }

  const closeClick = index => {
    dispatch({ type: 'close-dot', item: data.instructions[index] })
  }

  const goToNext = () => {
    const currentIndex = data.instructions.reduce((aggr, dot, index, array) => {
      if (dot.active === true) aggr = index
      return aggr
    }, -1)

    const currentDot = data.instructions[currentIndex]
      ? data.instructions[currentIndex]
      : data.instructions[0]

    const nextDot = data.instructions[currentIndex + 1]
      ? data.instructions[currentIndex + 1]
      : data.instructions[0]

    dispatch({ type: 'close-dot', item: currentDot })
    dispatch({ type: 'open-dot', item: nextDot })
  }

  const closeScreen = () => {
    dispatch({ type: 'start-training', item: true })
  }

  const updatePage = () => {
    dispatch({ type: 'start-test', item: true })
  }

  const showWelcome = data.userInfo.userOpened
  const userDone = data.userInfo.userFinished
  const open = data.instructions.reduce((aggr, item) => {
    if (item.active) aggr = true
    return aggr
  }, false)

  const page = data.userInfo.userStartedTest
    ? 2
    : 1

  return (
    <div style={{
      display: 'block',
      margin: '0 auto',
      textAlign: 'center',
    }}>

      {page === 2 && (
        <Form config={config} submitForm={submitForm} />
      )}

      {page === 1 && (
        <>
          {(!showWelcome) &&
            <WelcomeScreen config={config} closeScreen={closeScreen} />
          }

          {userDone &&
            <SuccesScreen config={config} updatePage={updatePage} />
          }

          <div style={{ position: 'relative', display: 'inline-block' }}>
            {showWelcome && (
              <Dots openClick={openClick} closeClick={closeClick} open={open} instructions={data.instructions} goToNext={goToNext} />
            )}

            <div style={{ opacity: (open || userDone || (!showWelcome)) ? '0.2' : '1' }}>
              {/* start HTML */}
              <div dangerouslySetInnerHTML={{ __html: html }}></div>
              {/* END HTML */}

            </div>
          </div>
          <NotificationContainer />
        </>
      )}

    </div>
  );
}

export default App;
