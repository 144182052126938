import React from 'react';
import PropTypes from 'prop-types';
import './MessageContainer.css';

const MessageContainer = ({ children }) => {
  return (
    <div className="message-container">
      {children}
    </div>
  )
}

MessageContainer.propTypes = {
  children: PropTypes.any,
};

export default MessageContainer;