import PropTypes from 'prop-types'

const AppReducer = (state, action) => {
  switch (action.type) {
    case ('submit-form'): {
      return {
        ...state,
        form: action.item
      }
    }
    case ('start-test'): {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userStartedTest: action.item
        }
      }
    }
    case ('finish-training'): {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userFinished: action.item,
        }
      }
    }
    case ('start-training'): {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userOpened: action.item,
        }
      }
    }
    case ('open-dot'): {
      const test = state.instructions.map(dot => {
        if (dot.title === action?.item?.title) {
          return {
            ...dot,
            active: true,
            opened: true,
          }
        }
        return dot
      })
      return {
        ...state,
        instructions: test
      }
    }
    case ('close-dot'): {
      const test = state.instructions.map(dot => {
        if (dot.title === action?.item?.title) {
          return {
            ...dot,
            active: false,
            opened: true,
            closed: true,
          }
        }
        return dot
      })

      return {
        ...state,
        instructions: test
      }
    }
    default: return state
  }
}

AppReducer.propTypes = {
  state: PropTypes.any,
  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    item: PropTypes.object
  })
}

export default AppReducer
