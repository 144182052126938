import React from 'react';
import PropTypes from 'prop-types';
import MessageContainer from './MessageContainer';

const WelcomeScreen = ({ config, closeScreen }) => {
  const welcome = config?.welcome
  return (
    <MessageContainer>
      <h1 className="content-header" style={{ margin: 0 }}>{welcome.header}</h1>
      <div dangerouslySetInnerHTML={{ __html: welcome.body.join(' ') }} />
      <button className="submit-button" style={{ cursor: 'pointer' }} onClick={() => closeScreen()}>{welcome.cta}</button>
    </MessageContainer>
  )
}

WelcomeScreen.propTypes = {
  config: PropTypes.object,
  closeScreen: PropTypes.func,
};

export default WelcomeScreen;