import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';


export const useDebounceEffect = (effect, delay, deps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
}

useDebounceEffect.propTypes = {
  effect: PropTypes.any.isRequired,
  delay: PropTypes.number.isRequired,
  deps: PropTypes.array,
};

export default useDebounceEffect;