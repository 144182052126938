export const config = {
  form: {

  },
  userInfo: {
    userFinished: false,
    userOpened: false,
    userStartedTest: false,
  },
  welcome: {
    header: 'Welcome',
    body: [
      '<p>Welcome to Ausgrid interactive Phishing and Awareness training. You have been nominated to complete this training module and will be asked to take a short assessment at the end of the training, please click ‘Begin’ to start your training.</p>',
    ],
    cta: 'Begin'
  },
  success: {
    header: 'Finished',
    body: [
      '<p>You have successfully finished reviewing all the training material for this module. Please click ‘Start Test’ to commence your assessment.</p>',
    ],
    cta: 'Start Test'
  },
  questionnaire: [
    {
      type: 'radio',
      title: 'I should never enter my Ausgrid username and password on a website that I have clicked on from an email received without performing checks to determine if it is a phishing website.',
      placeholder: '',
      required: true,
      options: [
        'True', 'False'
      ],
      answer: 'True',
    },
    {
      type: 'radio',
      title: 'What is the correct web address for the Microsoft Office 365 login? (hint: check the encryption/TLS certificates as previously advised)',
      options: [
        'office365-encryption.com',
        'Office365-login.com',
        'm1crosf0ft.com',
        'login.microsoftonline.com',
      ],
      placeholder: '',
      required: true,
      answer: 'login.microsoftonline.com',
    },
    {
      type: 'radio',
      title: 'If a website has grammatical errors or spelling mistakes, is it considered a phishing website?',
      options: [
        'True',
        'False'
      ],
      placeholder: '',
      required: true,
      answer: 'True',
    },
    {
      type: 'radio',
      title: 'What should I do if I have entered my Ausgrid username and password on a website that I suspect is phishing?',
      options: [
        'Change my password and immediately notify helpdesk by phone/email (helpdesk@ausgrid.com.au)',
        'Try and enter my username and password again',
        'Change my password immediately',
        'Check on google if the site is a phishing site',
      ],
      placeholder: '',
      required: true,
      answer: 'Change my password and immediately notify helpdesk by phone/email (helpdesk@ausgrid.com.au)',
    },
    {
      type: 'radio',
      title: 'I should inform IT support immediately if I suspect an email received or link I clicked on is a phishing scam?',
      options: [
        'True',
        'False',
      ],
      placeholder: '',
      required: true,
      answer: 'True',
    },
  ],
  submitDetails: {
    header: 'You have Submitted your results',
    body: [
      '<p>Thank you for completing your training assessment, your results have been captured and submitted.</p>',
    ]
  },
  instructions: [
    {
      position: {
        y: -160, x: 10
      },
      placement: 'right',
      title: 'Validate before entering your Credentials',
      body: [
        // '<p>If you have clicked on a link from an email you should never just enter your username and password without validation. Check to see if there is a ‘padlock’ on the top left corner of the web address as shown in the picture below (never enter username and passwords if there is no secure padlock) and try enter a fake username and password and see what happens? Usually entering different fake usernames and passwords will have the same result. </p>',
        '<p>If you have clicked on a link from an email you should never just enter your username and password without validation. Check to see if there is a ‘padlock’ on the top left corner of the web address as shown in the picture below (never enter credentials if there is no secure padlock). </p>',
        '<img width="100%" src="certificate.png" style="margin-top: 20px"  />',
      ],
      active: false,
      opened: false,
      closed: false,
    },
    {
      position: {
        y: -10, x: 60

      },
      placement: 'right',
      title: 'Ensure the certificate (TLS) is authentic',
      body: [
        '<p>Always click the padlock icon and view the certificate information to ensure it is verified by the correct vendor and the organisation name is correct. For example, if office365-encryption.com was a valid Microsoft site, the certificate would appear as "Verified By: Microsoft Corporation" or "Verified by: Digicert" and the organization name would be "Microsoft Corporation". If in doubt, always check with your IT support staff before signing in.</p>',
        '<img width="100%" src="security-click-padlock-01.png" style="margin-top: 20px" />',
      ],
      active: false,
      opened: false,
      closed: false,
    },
    {
      position: {
        y: 220, x: 290
      },
      placement: 'bottom',
      title: 'Check the website text and images',
      body: [
        '<p>Check the website for simple spelling mistakes, broken English, grammatical errors or low-resolution images which should act as a red flag that you are on a phishing site and should leave immediately. This is not always the case for all phishing websites (as you can see from this example) but you should always stay vigilant and check. </p>',
      ],
      active: false,
      opened: false,
      closed: false,
    },
    {
      position: {
        y: 170, x: 400
      },
      placement: 'bottom',
      title: 'What to do if you have been Phished?',
      body: [
        '<p> If you suspect you have received a phishing email or website and have entered your username and password please change your password immediately and contact IT Support via the following email: helpdesk@ausgrid.com.au </p>',
      ],
      active: false,
      opened: false,
      closed: false,
    },
  ]
}

export default config